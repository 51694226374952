import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ApiBindingModule } from "@api-binding/api-binding.module";

@NgModule({
    declarations: [],
    exports: [],
    imports: [CommonModule, ApiBindingModule],
})
export class CoreModule {}
