import { Injectable } from "@angular/core";

import { IPhotoboothGetDto } from "@dtos";
import { IImage } from "@models";

@Injectable()
export class ImageMapper {
    public mapGetImage = (imageDto: IPhotoboothGetDto): IImage => {
        return { ...imageDto };
    };
}
