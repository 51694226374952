export enum EnvironmentType {
    PROD = "prod",
    PREPROD = "preprod",
    TESTING = "testing",
    DEV = "dev",
}

export interface IEnvironment {
    inactivityTime: number;
    moustacheApiAssetsUrl: string;
    moustacheApiUrl: string;
    production: boolean;
    type: EnvironmentType;
}
