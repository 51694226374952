import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { IPhotoboothGetDto } from "@dtos";
import { environment } from "@environment";
import { IImage } from "@models";
import { ImageMapper } from "./image.mapper";

@Injectable()
export class ImageRepository {
    public constructor(
        private readonly http: HttpClient,
        private readonly imageMapper: ImageMapper,
    ) {}

    // get all images
    public getImages(): Observable<IImage[]> {
        const dataSource = this.http.get<IPhotoboothGetDto[]>(`${environment.moustacheApiUrl}/photobooth/`);

        return dataSource.pipe(
            map((images) => images.map(this.imageMapper.mapGetImage)),
            catchError(() => of([])),
        );
    }
}
