import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppRoutes } from "./routes";

const routes: Routes = [
    {
        loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
        path: AppRoutes.HOME,
    },
    {
        loadChildren: () => import("./puzzle/puzzle.module").then((m) => m.PuzzleModule),
        path: AppRoutes.PUZZLE,
    },

    {
        loadChildren: () => import("./geography-map/geography-map.module").then((m) => m.GeographyMapModule),
        path: AppRoutes.GEOGRAPHYMAP,
    },

    {
        path: AppRoutes.QUIZ,
        loadChildren: () => import("./quiz/quiz.module").then((m) => m.QuizModule),
    },
    {
        path: AppRoutes.COUNTRIES,
        loadChildren: () => import("./countries/countries.module").then((m) => m.CountriesModule),
    },
    {
        path: "**",
        redirectTo: AppRoutes.HOME,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes, { useHash: true })],
})
export class AppRoutingModule {}
