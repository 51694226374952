import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";

import { ImageMapper, ImageRepository } from "./index";
import { ErrorInterceptor } from "./interceptors/error.interceptor";

@NgModule({
    imports: [HttpClientModule],
    providers: [ImageMapper, ImageRepository, { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
})
export class ApiBindingModule {}
